import React from 'react'
import ExperimentGrid from '../components/gridBlocks/experimentGrid'

export default function Projects() {
  return (
    <>
      <ExperimentGrid />
    </>
  )
}
